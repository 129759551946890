import RPMDateTimePickerWithPlaceholder from '@/components/RPMDateTimePicker/RPMDateTimePickerWithPlaceholder';
import StyledModal from '@/components/StyledModal';
import { ActionRelation, COMPLETE } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import { DUPLICATE_BLOCK_WITH_ACTIONS_AND_EVENTS } from '@/gql/block';
import { DuplicateBlockWithActionsAndEventsResponse } from '@/gql/block/types';
import { CREATE_EVENTS } from '@/gql/events';
import { CreateEventsPayload, CreateEventsResponse } from '@/gql/events/types';
import { useUpdateActions } from '@/services/action/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { ActionUpdate } from '@/types/actions';
import { Block } from '@/types/block';
import { DayWeek } from '@/types/calendar';
import { formatDateToString } from '@/utils/calendar';
import { trackActionAddedToBlock, trackActionStarred, trackBlockCreated, trackCommitmentMade } from '@/utils/tracking';
import { Button, ModalBody, ModalFooter, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type ScheduleEventDateTime = { scheduledDate: string; scheduledTime: string };

type ScheduleEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  block: Block;
  reScheduleBlock?: boolean;
};

function DuplicateBlockModal({ isOpen, onClose, block, reScheduleBlock }: ScheduleEventModalProps) {
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState<typeof DAY | typeof WEEK>(DAY);
  const [dateRange, setDateRange] = useState(tabIndex === WEEK);
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const { data: weeklyPlanId } = useWeeklyPlanId(scheduledDate ?? new Date());

  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker' });

  const handleScheduledDate = useCallback(
    (date: Date) => {
      setScheduledDate(date);
    },
    [scheduledTime],
  );

  useEffect(() => {
    if (scheduledDate || !isDatePickerOpen) {
      return;
    }

    if (isDatePickerOpen) {
      setScheduledDate(new Date());
      return;
    }
  }, [isDatePickerOpen, scheduledDate]);

  const handleBlockUpdate = (projectId: string | undefined, message: string) => {
    queryClient.invalidateQueries(['blocks', 'all']);
    queryClient.invalidateQueries(['actions', 'all']);
    queryClient.invalidateQueries(['project', 'detail', projectId]);

    onClose();

    toast({
      title: message,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const updateActions = useUpdateActions(
    {
      onSuccess: async (data) => {
        const eventsPayload: CreateEventsPayload = { objects: [] };
        data.updateActionMany.forEach((action) => {
          action.returning?.forEach((returnedAction) => {
            if (returnedAction.scheduledDate && !returnedAction.event) {
              eventsPayload.objects.push({
                id: uuidv4(),
                actionId: returnedAction.id,
              });
            }
          });
        });

        await fetchData<CreateEventsResponse>(CREATE_EVENTS, eventsPayload);
        const projectIdReschedule = data.updateActionMany[0].returning[0].block?.projectId;
        handleBlockUpdate(projectIdReschedule, 'Block rescheduled successfully');
      },
    },
    false,
  );

  const onChangeTabs = useCallback((index: string | number) => {
    if ((index as DayWeek) === WEEK) {
      setScheduledTime(null);
    }

    setTabIndex(index as DayWeek);
    setDateRange((index as DayWeek) === WEEK);
  }, []);

  const onClickUnplan = useCallback(() => {
    setScheduledDate(null);
    setReady(false);
    onDatePickerClose();
  }, [onDatePickerClose]);

  const onDatePickerSave = useCallback(() => {
    setReady(true);
    onDatePickerClose();
  }, [onDatePickerClose]);

  const duplicateBlockWithActionsAndEvents = useMutation({
    mutationFn: (variables: { blockId: string; weeklyPlanId: string; scheduledDate: string | null }) =>
      fetchData<DuplicateBlockWithActionsAndEventsResponse>(DUPLICATE_BLOCK_WITH_ACTIONS_AND_EVENTS, variables),

    onSuccess: async (data) => {
      trackBlockCreated();

      data.duplicateBlockWithActionsAndEvents.actions.forEach((action) => {
        if (action.dateOfStarring) {
          trackActionStarred();
        }
        trackActionAddedToBlock(!!action.dateOfStarring);
      });

      data.duplicateBlockWithActionsAndEvents.promises.forEach((promise) => {
        if (promise.kind === ActionRelation.Commit) {
          trackCommitmentMade(promise.id);
        }
      });

      const projectIdDuplicate = data.duplicateBlockWithActionsAndEvents.block.project?.id;
      handleBlockUpdate(projectIdDuplicate, 'Block duplicated successfully');
    },
  });

  const reScheduleAllActions = async () => {
    const filteredActions = block!.actions!.filter((action) => action.progressStatus !== COMPLETE);
    const actionUpdates: ActionUpdate[] = filteredActions.map((action) => ({
      _set: {
        scheduledDate: scheduledDate && !dateRange ? formatDateToString(scheduledDate) : null,
        scheduledTime: null,
        weeklyPlanId: String(weeklyPlanId) ?? undefined,
      },
      where: {
        id: {
          _eq: action.id,
        },
      },
    }));

    await updateActions.mutateAsync(actionUpdates);
  };

  const handleOnSave = () => {
    !reScheduleBlock
      ? duplicateBlockWithActionsAndEvents.mutate({
          blockId: block.id,
          weeklyPlanId: String(weeklyPlanId) ?? undefined,
          scheduledDate: scheduledDate && !dateRange ? formatDateToString(scheduledDate) : null,
        })
      : reScheduleAllActions();
  };

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title={`What Day or Week would you like to ${reScheduleBlock ? 'move' : 'plan'} this Block ${reScheduleBlock ? 'to' : 'for'}?`}
      >
        <ModalBody>
          <RPMDateTimePickerWithPlaceholder
            scheduledTime={scheduledTime}
            onDatePickerOpen={onDatePickerOpen}
            isDatePickerOpen={isDatePickerOpen}
            onSave={onDatePickerSave}
            onCancel={onClickUnplan}
            placeholder="Choose Date"
            onClickUnplan={onClickUnplan}
            scheduledDate={scheduledDate}
            tabIndex={tabIndex}
            showDayWeekSelector
            onChangeTabs={onChangeTabs}
            dateRange={dateRange}
            updateSelectedDate={handleScheduledDate}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button
            isDisabled={(!ready && (!scheduledDate || !weeklyPlanId)) || isDatePickerOpen}
            onClick={handleOnSave}
            size="lg"
            variant="primary"
          >
            {reScheduleBlock ? 'Move' : 'Save'}
          </Button>
        </ModalFooter>
      </StyledModal>
    </>
  );
}

export default DuplicateBlockModal;
