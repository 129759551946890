import DayEventsDuration from '@/components/DayEventsDuration';
import rem from '@/utils/rem';
import { Box, Button, Collapse, HStack, Text, VStack } from '@chakra-ui/react';
import { format, isBefore, isToday, subDays } from 'date-fns';
import { useMemo } from 'react';

type CalendarDayHeaderProps = {
  date: Date;
  showBorder?: boolean;
  showDurations?: boolean;
  starredEventsDuration?: string;
  scheduledEventsDuration?: string;
  onClick: () => void;
};

export function CalendarDayHeader({
  date,
  showBorder = false,
  showDurations = false,
  onClick,
}: CalendarDayHeaderProps) {
  const dayOfWeek = useMemo(() => (isToday(date) ? 'Today' : format(date, 'EEEE')), [date]);
  const dayOfMonth = useMemo(() => format(date, 'MMMM d'), [date]);

  const textColor = useMemo(() => (isBefore(date, subDays(new Date(), 1)) ? 'gray.400' : 'text-primary'), [date]);
  const dayTextColor = useMemo(() => (isToday(date) ? 'button-bg-primary' : 'inherit'), [date]);

  return (
    <VStack
      gap="0"
      width="full"
      minWidth={rem(125)}
      borderBottom={`${rem(1)} solid`}
      borderBottomColor={isToday(date) ? 'cyan.500' : showBorder ? 'gray.500' : 'transparent'}
      borderRadius={`${rem(4)} ${rem(4)} 0 0`}
    >
      {/* WARNING, button inside a button: unfortunately react-big-calendar automatically wraps this component
      in another button, and it can't be configured otherwise. The structure of this component is correct
      (because we want only the top part to be clickable, not the durations at the bottom) and it works,
      but the outer button wrapper makes it incorrect from the accessibility/semantic-markup standpoint.
      Future solutions: 1) patch react-big-calendar to not wrap headers in buttons 2) remove the durations part
      or move it somewhere else, so we can use the outer button wrapper and make the entire header clickable */}
      <Button
        alignItems="flex-start"
        display="flex"
        width="full"
        height="auto"
        color={textColor}
        fontSize="xs"
        fontWeight={400}
        background="transparent"
        _hover={{ backgroundColor: 'background-secondary' }}
        flexFlow="column nowrap"
        onClick={onClick}
        paddingX={rem(8)}
        paddingY={rem(4)}
        transitionDuration="0.2s"
        transitionProperty="background-color"
      >
        {/* Note: data-date attribute is used elsewhere to retrieve data from the DOM */}
        {/* TODO: refactor that part to not need DOM lookup, or at least to have an explicit link to this component */}
        <Text color={dayTextColor} fontWeight={900} data-date={date.toISOString()}>
          {dayOfWeek}
        </Text>
        <Text marginTop={rem(2)}>{dayOfMonth}</Text>
      </Button>

      <Box as={Collapse} width="full" animateOpacity in={showDurations}>
        <HStack
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="row"
          width="full"
          paddingTop={rem(2)}
          paddingBottom={rem(12)}
          pointerEvents="visible"
          cursor="default"
          paddingX={rem(8)}
        >
          <DayEventsDuration date={date} textColor={textColor} small />
        </HStack>
      </Box>
    </VStack>
  );
}
